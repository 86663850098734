<template>
  <div>
    <header v-if="!isLoading" class="page-header">
      <PageTitle :prefix="'B2B Order: '" v-if="b2b_order" :check-translations="false" :item="b2b_order" property="id"/>
    </header>

    <div class="main-content">
      <div class="pane table-pane" v-if="b2b_order">
        <div class="pb-4">
          Dealer:
          <RouterLink target="_blank" class="font-700 text-wilmsBlue" :to="{ name: 'admin.dealer', params: { id: b2b_order.dealer.id }}">{{ b2b_order.dealer.name }}</RouterLink>
        </div>

        <formulate-form v-if="!isLoading" ref="b2b_orderForm" v-model="b2b_order" name="b2b_orderForm">
          <formulate-input :disabled="true" validation="required" label="Budget pre order" name="budget_pre_order" type="number"></formulate-input>
          <formulate-input :disabled="true" validation="required" label="Budget post order" name="budget_post_order" type="number"></formulate-input>
          <formulate-input :disabled="true" validation="required" label="Totale prijs ex. btw" name="total_price_ex_vat" type="number"></formulate-input>
        </formulate-form>

        <h3>Producten:</h3>

        <div class="table-wrapper">
          <div class="table-element">
            <table class="listData" v-if="!isLoading">
              <thead>
              <tr>
                <th class="id">ID</th>
                <th>Naam</th>
                <th>Aantal</th>
                <th>Eenheidsprijs</th>
                <th>Totaalprijs</th>
              </tr>
              </thead>
              <tbody>
              <!---->
              <tr :key="product.product_id" v-for="product in b2b_order.products">
                <td class="id">{{ product.product_id }}</td>
                <td>
                  <RouterLink class="font-700 text-wilmsBlue" target="_blank" :to="{ name: 'admin.product', params: { id: product.product_id }}">{{ getTranslation(product.product).name }}</RouterLink>
                </td>
                <td>{{ product.amount ? product.amount : '/' }}</td>
                <td>{{ $n(product.unit_price ? product.unit_price : product.product.unit_price, 'currency', 'nl-BE') }}</td>
                <td>{{ product.total_price ? $n(product.total_price, 'currency', 'nl-BE') : '/' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import PageTitle from '@/components/admin/PageTitle'
import { DateTime } from 'luxon'

export default {
  name: 'b2b_order',
  components: {
    PageTitle
  },
  data: function () {
    return {
      id: this.$route.params.id,
      isLoading: true,
      b2b_order: null,
      datetime: DateTime
    }
  },
  async mounted () {
    if (this.id) {
      await ApiService.fetchB2BOrder(this.id).then(res => {
        this.b2b_order = res.data
      })
    }

    this.isLoading = false
  }
}
</script>
